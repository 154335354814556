// import React from "react"

export const solutionPlanActionContent = {
  title: "Power your subscriptions with Zapier.",
  description: "Once customers sign-up and as their subscription changes, use Zapier to complete follow up tasks across all of the other tools and services you use to run your business.",
  image: require('../images/plan-action.svg'),
  svg: true,
  imageName: 'plan action image',
  // svg: true,
  cards: [
    {
      image: 'plan-action-1.png',
      text: 'Add the new customer to your CRM system',
    },
    {
      image: 'plan-action-2.png',
      srcSet:'plan-action-2@2x.png 2x',
      text: 'Subscribe them to the correct mailing list',
    },
    {
      image: 'plan-action-3.png',
      text: 'Post out their welcome pack',
    }
  ],
  url: '/product/integrations/zapier',
  buttonColor: 'blue',
  buttonText: 'Learn about Zapier',
  comingSoonText:''
}